import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"
import {PrismicRichText} from "@prismicio/react"
import styled from "styled-components"

const ArticleList = styled.div`
  max-width: var(--max-width-section-text);
  margin: 0 auto;
`

const Article = styled.div`
  background: #fff;
  padding: 20px;
  margin: 50px 10px;
  box-shadow: 4px 4px 14px 3px rgba(0, 0, 0, 0.2);

  .divider {
    border-bottom: 1px solid #ddd;
  }

  h2 {
    margin: 12px 0 24px 0;
    font-size: 1.8em;
  }

  li {
    padding: 0 3px 0 30px;
    display: block;
    font-size: 1.1rem;
    margin-left: -3px;
    background: url('/list_mark.png') 4px 9px no-repeat;
  }
  
  p {
    margin: 16px 0 6px 0;
  }

  small {
    color: #666;
  }

  @media screen and (max-width: 767px) {
    margin: 30px 10px;
    padding: 10px;
    li {
      font-size: 1rem;
    }
  }
`

const LinkButton = styled.button`
  padding: 12px 26px;
  background: #40a7c9;
  color: white;
  border: none;
  font-size: 1.4em;

  a {
    text-decoration: none;
    color: white;
  }
`


const ArticlePage = ({data}) => {

    if (!data) return null

    const pageContent = data.prismicArticle || {};
    return (
        <>
            <SliceZone
                body={pageContent.data.body}
                page="ajankohtaista"
            />

            {<ArticleList>
                {data.allPrismicPost.edges.map((node, index) => {

                    return (
                        <Article key={index}>
                            <small>{node.node.data.date}</small>
                            <PrismicRichText field={node.node.data.body[0].primary.section_title.richText}/>
                            <PrismicRichText field={node.node.data.body[0].primary.section_text.richText}/>

                            {/*<LinkButton>
                                <Link
                                    to={`/ajankohtaista/${node.node.uid}`}>
                                    Lue lisää
                                </Link>
                            </LinkButton>*/}
                        </Article>
                    );
                })}
            </ArticleList>}
        </>
    );
};

export const Head = () => (
    <Seo title="Ajankohtaista Minnesota-hoidosta" />
);


export const query = graphql`
  query ArticlesQuery($lang: String) {
    prismicArticle(lang: { eq: $lang }) {
        uid
        id
        lang
        data {
          title {
            richText
          }
          body {
            ... on PrismicArticleDataBodyHero {
              id
              slice_type
              primary {
                hero_title {
                  richText
                }
                hero_content
                background_image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
    }
    
    allPrismicPost(sort: {order: DESC, fields: data___date}) {
        edges {
          node {
            id
            uid
            data {
              title {
                richText
              }
              date(formatString: "DD.MM.yyyy")
              body {
                ... on PrismicPostDataBodyTextSection {
                  id
                  slice_type
                  primary {
                    section_title {
                      richText
                    }
                    section_text {
                      richText
                    }
                  }
                  items {
                    image_title {
                      richText
                    }
                    image_text {
                      richText
                    }
                    image {
                      fluid {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    
   
  }
`

export default ArticlePage;



